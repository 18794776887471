/* @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .marquee-container {
    overflow: hidden;
    position: relative;
  }
  
  .marquee {
    display: inline-block;
    white-space: nowrap;
  }
  
  .marquee:hover {
    animation: marquee 10s linear infinite alternate;
  }
   */

/* marquee.css */
.marquee-container {
    overflow: hidden;
    width: 100%;
  }
  
  .marquee {
    white-space: nowrap;
    display: inline-block;
    transition: transform 8s linear;
    transform: translateX(0); /* Start from the original position */
  }
  
  .marquee-container:hover .marquee {
    transform: translateX(-100%);
  }
  
  .marquee-container:not(:hover) .marquee {
    transform: translateX(0); /* Reset to the original position */
    transition: none; /* Disable the transition when not hovered */
  }
  

  .Profilemenu {
    background-color: #bb2649;
    color: white;
    text: white;
  }