body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide Google Translate branding */
.goog-logo-link {
  display: none !important;
}

/* Hide Google Translate popup */
.goog-te-banner-frame {
  display: none !important;
}


/* Show translated text within select dropdown */
.goog-te-combo{
  visibility: visible !important;
}
.VIpgJd-ZVi9od-l4eHX-hSRGPd{
  display: none !important;
}
body > .skiptranslate > iframe.skiptranslate {
  display: none !important;
  visibility: hidden !important;
 }
 body {
  top: 0px !important;
 }
 .goog-logo-link {
  display:none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
  color: black !important;
}
